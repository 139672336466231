import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Moment from "moment";
import { useLocation } from 'react-router-dom'

import { DataGrid, GridRowsProp, GridColDef, GridToolbar, GridCellEditStopReasons, useGridApiRef } from '@mui/x-data-grid';
import parse from 'html-react-parser';
import clsx from 'clsx';

import { Link } from "react-router-dom";

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import CircleIcon from '@mui/icons-material/Circle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Avatar from '@mui/material/Avatar';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';

import LinearProgress from '@mui/material/LinearProgress';

import { useParams } from "react-router-dom";

import UserContext from '../components/user-data';


import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';



var server = 'https://ase-server.vercel.app/api/';
//const queryParams = new URLSearchParams(window.location.search)
//const video = queryParams.get("event")

const Search = (props) => {
  const { user, loginWithRedirect, isAuthenticated } = useAuth0();
  const profile = useContext(UserContext);
  const apiRef = useGridApiRef();

//  const location = useLocation()
//  
//  if (!location || !location.state) {
//    loginWithRedirect()
//  }
//
//  const { product, item_name } = location.state
    
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('beltRank');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [updatedRows, setUpdatedRows] = React.useState();
    
  const [snackbar, setSnackbar] = React.useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  document.title = `ASEN - All Fighters`;

  const [fighters, setFighters] = React.useState([]);
  const [fighterList] = React.useState([]);
  const [rows] = React.useState([]);
  const [rowId, setRowId] = React.useState(null);
        
  const columns: GridColDef[] = [
      { field: 'firstName', headerName: 'First', minWidth: 120, align: 'left', headerAlign: 'left' },
      { field: 'lastName', headerName: 'Last', minWidth: 120, align: 'left', headerAlign: 'left' },
      { field: 'gender', 
        headerName: 'Gender', 
        minWidth: 100, 
        align: 'left', 
        headerAlign: 'left', 
        type: 'singleSelect', 
        valueOptions: ['male', 'female'],
        renderCell: (params) => {
            if (params.value === "male") {
                return <div className="align-middle"><MaleIcon sx={{ fontSize: 16 }} className="gender male" /> <span>{params.value}</span></div>
            }
            else {
                return <div className="align-middle"><FemaleIcon sx={{ fontSize: 16 }} className="gender female" /> <span>{params.value}</span></div>
            }
          
        },
      },
      { field: 'dob', headerName: 'Age (y)', minWidth: 100, type: 'number', align: 'right', headerAlign: 'right' },
      { field: 'weight', headerName: 'Weight (lbs)', minWidth: 120, type: 'number', align: 'right', headerAlign: 'right' },
      { field: 'beltRank', 
        headerName: 'Belt', 
        editable: true, 
        minWidth: 100, 
        align: 'left', 
        headerAlign: 'left', 
        type: 'singleSelect', 
        valueOptions: ['white', 'gray', 'yellow', 'orange', 'green', 'blue', 'purple', 'brown', 'black'],
        renderCell: (params) => {
            if (params.value === null){
                return <div className="align-middle"><span>–</span></div>
            }
            else if (params.value === "white") {
                return <div className="align-middle"><FiberManualRecordIcon fontSize="xsmall" sx={{ color: "Gainsboro" }} /> <span>{params.value}</span></div>
            }
            else if (params.value === "gray") {
                return <div className="align-middle"><FiberManualRecordIcon fontSize="xsmall" sx={{ color: "DimGray" }} /> <span>{params.value}</span></div>
            }
            else if (params.value === "yellow") {
                return <div className="align-middle"><FiberManualRecordIcon fontSize="xsmall" sx={{ color: "Gold" }} /> <span>{params.value}</span></div>
            }
            else if (params.value === "orange") {
                return <div className="align-middle"><FiberManualRecordIcon fontSize="xsmall" sx={{ color: "Orange" }} /> <span>{params.value}</span></div>
            }
            else if (params.value === "green") {
                return <div className="align-middle"><FiberManualRecordIcon fontSize="xsmall" sx={{ color: "MediumSeaGreen" }} /> <span>{params.value}</span></div>
            }
            else if (params.value === "blue") {
                return <div className="align-middle"><FiberManualRecordIcon fontSize="xsmall" sx={{ color: "DodgerBlue" }} /> <span>{params.value}</span></div>
            }
            else if (params.value === "purple") {
                return <div className="align-middle"><FiberManualRecordIcon fontSize="xsmall" sx={{ color: "MediumSlateBlue" }} /> <span>{params.value}</span></div>
            }
            else if (params.value === "brown") {
                return <div className="align-middle"><FiberManualRecordIcon fontSize="xsmall" sx={{ color: "Sienna" }} /> <span>{params.value}</span></div>
            }
            else if (params.value === "black") {
                return <div className="align-middle"><FiberManualRecordIcon fontSize="xsmall" sx={{ color: "Black" }} /> <span>{params.value}</span></div>
            } 
        },
      },
      { field: 'trainingSince', headerName: 'Experience (y)', minWidth: 150, type: 'number', align: 'right', headerAlign: 'right' },
      { field: 'matchPref', headerName: 'Preference', minWidth: 150, align: 'left', headerAlign: 'left', type: 'singleSelect', valueOptions: ['GI', 'No-GI', 'any'] },
      { field: 'style', headerName: 'Style', minWidth: 150, align: 'left', headerAlign: 'left', type: 'singleSelect', valueOptions: ['balanced', 'guard player', 'top player', 'wrestler', 'high paced'] },
      { field: 'gymAffiliation', headerName: 'Gym', minWidth: 240, align: 'left', headerAlign: 'left' },
      { field: 'appliedEvents', 
        headerName: 'Applied Events', 
        minWidth: 400, 
        align: 'left', 
        headerAlign: 'left',
        renderCell: (params) => {  
          
          if (params.value === null || params.value === "" || params.value === undefined || params.value.length === 0){
              return "–"
          }
          else { 
            //var listEvents = [];
            //
            //{params.value.map((el, index) => (
            //  listEvents.push(el.event)
            //))}
            //
            //return listEvents.toString()
            
            return params.value
            //return (
            //  <div>
            //    {params.value.map((el, index) => (
            //      
            //      <span>{el.event} {params.value.length > 0 && index !== params.value.length - 1 ? <span className='divider'> | </span> : ""} </span>
            //    ))}
            //  </div>
            //);
          }
        }
      },
      { field: 'location', 
        headerName: 'Location', 
        minWidth: 240, 
        align: 'left', 
        headerAlign: 'left', 
        renderCell: (params) => {
            if (params.value === null || params.value === "" || params.value === undefined){
                return "–"
            }
            else {
                splitAddress = params.value.split(",");
                locationCity = splitAddress[0];
                locationState = splitAddress[1].substring(1);

                var link = `https://www.google.com/maps/search/?api=1&query=${locationCity}+${locationState}`;
                return <a href={link} className="table-link" target='_blank'>{params.value}</a> 
            }
            
            
        },
      },
      { field: 'email', 
        headerName: 'Email',
        minWidth: 250, 
        align: 'left',
        headerAlign: 'left',
        renderCell: (params) => {
            if (params.value === null){
                return "–"
            }
            
            var link = `mailto:${params.value}`
            return <a href={link} className="table-link" target='_blank'>{params.value}</a>
        },
      },
      { field: 'instagram', 
        headerName: 'Instagram', 
        minWidth: 220, 
        align: 'left', 
        headerAlign: 'left',
        renderCell: (params) => {
            if (params.value === null){
                return "–"
            }
            
            var link = `https://instagram.com/${params.value}`
            return <a href={link} className="table-link" target='_blank'><AlternateEmailIcon sx={{ fontSize: 12, paddingRight: 0.2 }} />{params.value}</a>
        },
      },
      { field: 'phone', 
        headerName: 'Phone', 
        minWidth: 150, 
        align: 'right', 
        headerAlign: 'right',
        renderCell: (params) => {
            if (params.value === null){
                return "–"
            }
            phoneUrl = params.value.replace(/\D/g,'');

            var link = `tel:${phoneUrl}`
            return <a href={link} className="table-link" target='_blank'>{params.value}</a>
        },
      },
      

  ];


 function createData(id, firstName, lastName, gender, email, instagram, phone, location, dob, weight, beltRank, gymAffiliation, trainingSince, matchPref, style, appliedEvents) {
  return {
    id,
    firstName,
    lastName,
    gender,
    email,
    instagram,
    phone,
    location,
    dob,
    weight,
    beltRank,
    gymAffiliation,
    trainingSince,
    matchPref,
    style,
    appliedEvents
  };
}

  var id = '';
  var email = '';
  var role = '';
  var isActive = '';
  var firstName = '';
  var lastName = '';
  var instagram = '';
  var phone = '';
  var location = '';
  var dob = '';
  var gender = '';
  var weight = '';

  var beltRank = '';
  var gymAffiliation = '';
  var trainingSince = '';

  var matchPref = '';
  var style = '';

  var appliedEvents = [];
  var coach = '';
  var song = '';
  var commentary = '';  
  var eventName = '';
  var eventProduct = '';
  var matched = ''; 
  var phoneUrl = '';
  var splitAddress = '';
  var locationCity = '';
  var locationState = '';


//  apiRef.current.stopCellEditMode({ id: 1, field: 'name' });

const handleProcessRowUpdate = (updatedRow, originalRow) => {
  // Find the index of the row that was edited
  const rowIndex = rows.findIndex((row) => row.id === updatedRow.id);
    
//  console.log(product, updatedRow.email, updatedRow.matched);

  // Replace the old row with the updated row
  const updatedRows = [...rows];
  updatedRows[rowIndex] = updatedRow;

  // Update the state with the new rows
  setUpdatedRows(updatedRows);

  // Return the updated row to update the internal state of the DataGrid
  handleChange(updatedRow.id, updatedRow.email, updatedRow.beltRank)
    
  setSnackbar({ children: 'User successfully saved', severity: 'success' });
    
    
  return updatedRow;
};

  const handleChange = (id, email, beltRank) => {
        
        //console.log(email, beltRank);
    
        updateUser()
        
//        console.log(id + " " + email + " " + value + " " + product)
        
        async function updateUser() {
          const updatingUser = await fetch (`${server}users/update-fighter-details`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: email,
              beltRank: beltRank
            }),
          })
          .then((response) => response.json())
          .then((data) => {
    //        document.getElementById('status').reset();
    //        window.location.assign("/profile");
    //        setOpen(false);
    //        getUser()
    //        setProfile(data);
    //        window.location.reload();
    //        window.location.assign("/");
    //        rows = []
//              console.log(product, email, value);

          })
          .catch((error) => {
            console. error ('Error:', error);
          });
        }
  };

  async function getFighters() {
    const fetchingEvent = await fetch (`${server}all-fighters`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: user.email,
      }),
    })
    .then((response) => response.json())
    .then((data) => {

      setFighters(data);
      // console.log(data)
    })
    .catch((error) => {
      console. error ('Error:', error);
    });
  }

  async function renderFighters(){
    //content
    const loadingEvents = await fighters.forEach((item, index)=>{
      
      id = item._id;
      email = item.email;
      role = item.role;
      isActive = item.fighter.isActive;
      firstName = item.fighter.firstName;
      lastName = item.fighter.lastName;
      instagram = item.fighter.instagram;
      phone = item.fighter.phone;
      location = item.fighter.location;
      dob = item.fighter.dob;
      gender = item.fighter.gender;  

      weight = item.fighter.weight;
      beltRank = item.fighter.beltRank;
      gymAffiliation = item.fighter.gymAffiliation;

      trainingSince = item.fighter.trainingSince;
      matchPref = item.fighter.matchPref;

      style = item.fighter.style;
      appliedEvents = item.appliedEvents;
      
      if (appliedEvents && appliedEvents.length > 0) {
        var listEvents = [];
            
        {appliedEvents.map((el, index) => (
          listEvents.push(el.event)
        ))}

        appliedEvents = listEvents.toString()
      }
      
      
      //item.appliedEvents.forEach((event, index)=>{
              
        //if (event.product === product) {
            //coach = event.coach;
            //song = event.song;
            //commentary = event.commentary;
            //eventName = event.event;
            //eventProduct = event.product;
            //matched = event.matched;
        //}  
      //})
      
      //appliedEvents = appliedEvents.toString();
        
      //console.log(appliedEvents); 
      
      dob = Moment().diff(Moment(dob), "years");
      trainingSince = Moment().diff(Moment(trainingSince), "years", true);
      trainingSince = Math.round(trainingSince * 10) / 10

      if (matched === null){
        matched = "Unmatched";
      }
        
      
      var fighter = createData(id, firstName, lastName, gender, email, instagram, phone, location, dob, weight, beltRank, gymAffiliation, trainingSince, matchPref, style, appliedEvents)
      
      rows.push(fighter)
          
      return rows
      


    });     

  }

// console.log(product)
//useEffect(() => {
    if (isAuthenticated && !profile) {
        return <div className="App-cover"><div className="App-container">Loading ...</div></div>;
    }

    else if (isAuthenticated === false || profile.role !== "admin") {
        loginWithRedirect()
    } 

    else {
        if (fighters && fighters.length === 0) {
            getFighters();

//            console.log("Fetching Fighters");
        }
        if (rows && rows.length === 0) {
            renderFighters();
//            console.log("Loading Fighters");
        }
    }
//});



if (rows.length === 0) {
    return <div className="App-cover"><div className="App-container">Loading fighters...</div></div>;
}
else {
 return (
    isAuthenticated && rows && (
      <div className="App-cover">
        <div className="App-container library">
            <Box sx={{ width: '100%' }}>
              <Paper 
                sx={{ 
                    width: '100%', 
                    mb: 2, 
                    padding: '20px 0px 0px',
                    '& .status.matched': {
                      backgroundColor: '#87db81',
                      color: '#2a4428',
                    },
                    '& .status.pending': {
                      backgroundColor: '#f0cd31',
                      color: '#3f360b',
                    },
                    '& .status.rejected': {
                      backgroundColor: '#ff6262',
                      color: '#fff',
                    },
                    '& .gender.male': {
                      color: '#02a3ff',
                    },
                    '& .gender.female': {
                      color: '#f295a5',
                    },
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 12px', alignItems: "center", gap: "16px" }}>
                    <Typography
                      sx={{ flex: '1 1 100%', textAlign: 'left' }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      All Fighters
                    </Typography>
                    <Typography
                      sx={{ flex: '1 1 100%', textAlign: 'right', maxWidth:"80px" }}
                      variant="body"
                      id="tableTitle"
                      component="div"
                    >
                      {rows.length} total 
                    </Typography>
                    
                </Box>

                <DataGrid 
                    rows={rows} 
                    columns={columns}
                    disableColumnMenu={true}
                    disableColumnResize={false}
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 200}
                    loading={rows.length === 0}
                    density="compact"
                    apiRef={apiRef}
                    processRowUpdate={handleProcessRowUpdate}
                    onProcessRowUpdateError={(error) => console.log(error)}
                    getRowId={(row) => row.id}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'beltRank', sort: 'asc' }],
                      },
                      pagination: {
                        paginationModel: {
                          pageSize: 50,
                        },
                      },
                    }}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    pageSizeOptions={[5, 10, 25, 50, 100 ]}
                    sx={{ 
                        '& .MuiDataGrid-columnSeparator': { 
                            display: 'none' 
                        },  
                        '&, [class^=MuiDataGrid-root]': { 
                            border: 'none' 
                        },
                        '& .MuiDataGrid-virtualScroller.css-qvtrhg-MuiDataGrid-virtualScroller': {
                            minHeight: '100px'
                        },
                        '& .css-wop1k0-MuiDataGrid-footerContainer': {
                            borderTop: '0'
                        },
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                        '&, .MuiDataGrid-cell--textRight .MuiDataGrid-cellContent': {textAlign: 'right !important'},
                        '&, .MuiDataGrid-cell--textLeft .MuiDataGrid-cellContent': {textAlign: 'left !important'},
                        '.MuiDataGrid-columnHeaderTitle': {fontWeight: '600'},
                        '.MuiDataGrid-columnHeader:hover': {background: 'whitesmoke'},
                        '.MuiDataGrid-toolbarContainer button': {color: '#E36B58'},
                        '.MuiDataGrid-toolbarContainer button:hover': {background: 'rgba(227, 107, 89, 0.05)'},
                        '.MuiDataGrid-toolbarContainer': {padding: '12px 8px'},
                    }}
                />
                {!!snackbar && (
                    <Snackbar
                      open
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      onClose={handleCloseSnackbar}
                      autoHideDuration={6000}
                    >
                      <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}

              </Paper>
            </Box>
        </div>
      </div>
    )
  );
 }
};

export default Search;


// handleChange(params.id, params.email, params.value, product)
// processRowUpdate={ (params, originalParams) => console.log(params.id, params.row.email, params.value, product, params, originalParams)}